<template>
  <div>
    <template v-if="loading">
      <div class="pa-9 mx-10">
        <v-skeleton-loader
          type="card, card, article, date-picker"
        ></v-skeleton-loader>
      </div>
    </template>
    <template v-else>
      <validation-observer ref="observer">
        <form @submit.prevent="save">
          <div>
            <div class="pa-9 mx-10">
              <v-container>
                <v-row align="center">
                  <v-col cols="12" class="mb-6">
                    <h3 class="page-title font-weight-bold">クレーム編集</h3>
                    <span>クレーム情報を編集します。</span>
                  </v-col>
                  <v-col cols="12">
                    <div class="form-content">
                      <v-row align="center">
                        <v-col
                          cols="3"
                          class="form-header text-heading-3 text-right"
                          >顧客名</v-col
                        >
                        <v-col cols="4" class="flex-grow-1">
                          <validation-provider
                            v-slot="{ errors }"
                            name="お客様"
                            rules="required"
                          >
                            <v-autocomplete
                              class="customer-box-form-input"
                              :error-messages="errors"
                              :error="errors.length !== 0"
                              hide-details="auto"
                              placeholder="顧客名を入力してください"
                              outlined
                              :items="customers"
                              item-text="name"
                              item-value="id"
                              dense
                              autocomplete="off"
                              v-model="data.customer"
                              :search-input.sync="search"
                              hide-no-data
                              :loading="smallLoading.customer"
                              @change="updateSiteList()"
                              @update:search-input="searchCustomer"
                              return-object
                            >
                            </v-autocomplete>
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <v-row align="center">
                        <v-col
                          cols="3"
                          class="form-header text-heading-3 text-right"
                          >物件名</v-col
                        >
                        <v-col cols="4" class="flex-grow-1">
                          <validation-provider
                            v-slot="{ errors }"
                            name="sites"
                            rules="required"
                          >
                            <v-select
                              class="customer-box-form-input"
                              hide-details="auto"
                              placeholder="物件名を入力してください"
                              outlined
                              dense
                              :items="sites"
                              :error-messages="errors"
                              :error="errors.length !== 0"
                              v-model="data.site_id"
                              item-text="name"
                              item-value="id"
                              :disabled="!data.customer_id"
                              :loading="smallLoading.site"
                            ></v-select>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col
                          cols="3"
                          class="form-header text-heading-3 text-right"
                          >サービスタイプ</v-col
                        >
                        <v-col cols="4" class="flex-grow-1">
                          <validation-provider
                            v-slot="{ errors }"
                            name="services"
                            rules="required"
                          >
                            <v-select
                              class="form-text"
                              dense
                              outlined
                              placeholder="サービスタイプを選択してください"
                              hide-details
                              v-model="data.service_id"
                              :error-messages="errors"
                              :error="errors.length !== 0"
                              :items="services"
                              item-value="id"
                              item-text="name"
                              :disabled="!data.site"
                            ></v-select>
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <v-row align="center">
                        <v-col
                          cols="3"
                          class="form-header text-heading-3 text-right"
                          >タスク</v-col
                        >
                        <v-col cols="4" class="flex-grow-1">
                          <validation-provider
                            v-slot="{ errors }"
                            name="クレーム分類"
                            rules="required"
                          >
                            <v-select
                              class="form-text"
                              dense
                              outlined
                              placeholder="task id / 日付 / サービス名 "
                              hide-details="auto"
                              :error-messages="errors"
                              v-model="data.task_id"
                              :items="tasks"
                              item-value="id"
                              item-text="text"
                              :disabled="data.service_id === null"
                            ></v-select>
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <v-row align="center">
                        <v-col
                          cols="3"
                          class="form-header text-heading-3 text-right"
                          >クレーム分類</v-col
                        >
                        <v-col cols="4" class="flex-grow-1">
                          <validation-provider
                            v-slot="{ errors }"
                            name="クレーム分類"
                            rules="required"
                          >
                            <v-select
                              class="form-text"
                              outlined
                              dense
                              :items="claimCategories"
                              :error-messages="errors"
                              :error="errors.length !== 0"
                              hide-details="auto"
                              placeholder="クレーム分類を選択してください"
                              chips
                              multiple
                              clearable
                              v-model="data.claim_category_id"
                            >
                              <template
                                v-slot:selection="{ attrs, item, select }"
                              >
                                <v-chip
                                  class="mb-1"
                                  label
                                  small
                                  close
                                  color="#8C5F5F"
                                  text-color="white"
                                  v-bind="attrs"
                                  @click="select"
                                  @click:close="removeTag(item.value)"
                                >
                                  <span>{{ item.text }}</span>
                                </v-chip>
                              </template>
                            </v-select>
                          </validation-provider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="3"
                          class="form-header text-heading-3 text-right"
                          >クレーム内容</v-col
                        >
                        <v-col cols="9" class="flex-grow-1">
                          <validation-provider
                            v-slot="{ errors }"
                            name="des"
                            :rules="`required|max:${descriptionMaxLength}`"
                          >
                            <v-textarea
                              class="form-text"
                              dense
                              outlined
                              placeholder="クレームの内容を箇条書きにしてください"
                              hide-details="auto"
                              :error-messages="errors"
                              v-model="data.description"
                              :maxlength="descriptionMaxLength"
                            >
                            </v-textarea>
                          </validation-provider>
                          <div class="text-right mt-1">
                            {{
                              `${data.description.length}/${descriptionMaxLength}`
                            }}文字
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="3"
                          class="form-header text-heading-3 text-right"
                          >対応内容</v-col
                        >
                        <v-col cols="9" class="flex-grow-1">
                          <validation-provider
                            v-slot="{ errors }"
                            name="res"
                            :rules="`required|max:${responseMaxLength}`"
                          >
                            <v-textarea
                              class="form-text"
                              dense
                              outlined
                              placeholder="どのように対応したかを記入してください"
                              hide-details
                              :error-messages="errors"
                              :error="errors.length !== 0"
                              v-model="data.response"
                              :maxlength="responseMaxLength"
                            ></v-textarea>
                          </validation-provider>
                          <div class="text-right mt-1">
                            {{
                              `${data.response.length}/${responseMaxLength}`
                            }}文字
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div class="create-task-footer px-6">
              <v-row>
                <v-col cols="auto" class="flex-grow-1">
                  <v-btn
                    color="#AD4545"
                    text
                    rounded
                    min-width="150"
                    @click="deleteClaim"
                    :loading="loading"
                  >
                    <v-icon left>$trash</v-icon>
                    削除する
                  </v-btn>
                </v-col>

                <v-col cols="auto">
                  <v-btn
                    :to="{
                      name: 'ClaimList'
                    }"
                    text
                    rounded
                    min-width="150"
                    class="mr-8"
                    >キャンセル</v-btn
                  >
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    @click="save"
                    color="#4F55A7"
                    class="white--text"
                    min-width="150"
                    :disabled="smallLoading.site || smallLoading.customer"
                    :loading="smallLoading.submit"
                    >更新する</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
        </form>
      </validation-observer>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
export default {
  name: 'Edit',
  components: {},
  props: ['id'],
  data() {
    return {
      loading: true,
      smallLoading: {
        customer: false,
        site: false,
        submit: false
      },
      initialLoad: true,
      search: '',
      customers: [],
      data: {
        service_id: null,
        description: '',
        response: '',
        task_id: '',
        claim_category_id: [],
        customer_id: 0,
        customer: null,
        site_id: 0,
        site: null,
        created_by: '',
        updated_by: '',
        claim_id: ''
      },
      descriptionMaxLength: 1000,
      responseMaxLength: 1000
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'searchedCustomers',
      'allSites',
      'allServices',
      'allClaimCategories',
      'claimById'
    ]),
    claim() {
      return this.claimById
    },
    claimCategories() {
      return this.allClaimCategories.map(category => {
        return { value: category.id, text: category.name }
      })
    },
    sites() {
      return this.allSites
    },
    services() {
      if (!this.data.site?.projects) return []
      return this.data.site?.projects.map(service => {
        return {
          id: service.service_type_id,
          name: service.service_type
        }
      })
    },
    tasks() {
      return this.data.site?.tasks
        ?.filter(task => task.service_type_id === this.data.service_id)
        .map(task => {
          return {
            id: task.id,
            text: `${task.id} / ${task.date} / ${task.service.name}`
          }
        })
    }
  },
  async created() {
    await this.$store.dispatch('CLAIM_CATEGORY_GET_ALL')
    // await this.$store.dispatch('SERVICE_GET_ALL')
    this.getDataFromApi()
  },
  methods: {
    ...mapMutations(['showModal']),
    async getDataFromApi() {
      await this.$store.dispatch('CLAIM_GET_BY_ID', this.id).then(() => {
        this.loading = false
      })
      this.populateCustomerList()
      this.fillFields()
      this.updateSiteList()
      this.initialLoad = false
    },
    populateCustomerList() {
      let currentCustomer = this.claim.customer
      let customers = [currentCustomer]
      if (this.search) {
        customers.push(...this.searchedCustomers)
      }
      this.customers = customers
    },
    searchCustomer: debounce(async function() {
      if (!this.search || this.search === this.data.customer?.name) return
      let params = {}
      params.search = this.search
      params.without_site = true
      this.smallLoading.customer = true
      this.$store.dispatch('CUSTOMER_GET_ALL', params).then(() => {
        this.smallLoading.customer = false
        this.populateCustomerList()
      })
    }, 1000),
    fillFields() {
      if (this.claim) {
        this.data.description = this.claim.description
        this.data.response = this.claim.response
        this.data.task_id = this.claim.task_id
        this.data.claim_category_id = this.claim?.categories.map(category => category.id)
        this.data.customer_id = this.claim.customer_id
        this.data.site_id = this.claim.site_id
        this.data.customer = this.customers.find(
          customer => customer.id === this.claim.customer_id
        )
        this.data.service_id = this.claim.task.project.service_type_id
        this.data.created_by = this.user.id
        this.data.updated_by = this.user.id
      }
    },
    updateSiteList() {
      if (!this.initialLoad) {
        this.data.site = null
        this.data.service_id = null
      }

      let params = {}
      params.id = this.data.customer_id
      this.smallLoading.site = true
      this.$store.dispatch('GET_SITE_BY_CUSTOMER', params).then(() => {
        this.smallLoading.site = false
        this.data.site = this.sites.find(site => site.id === this.claim.site_id)
      })
    },
    save() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        this.smallLoading.submit = true
        let data = {}

        data.claim_id = this.claim.id
        data.description = this.data.description
        data.response = this.data.response
        data.task_id = this.data.task_id
        data.claim_category_id = this.data.claim_category_id
        data.customer_id = this.data.customer_id
        data.created_by = this.user.id
        data.updated_by = this.user.id

        this.$store
          .dispatch('CUSTOMER_EDIT_CLAIM', data)
          .then(
            response => {
              if (response.data.status == 'success') {
                this.$router.push({
                  name: 'ClaimList'
                })
              }
            },
            error => {
              throw error
            }
          )
          .finally(() => (this.smallLoading.submit = false))
      })
    },
    removeTag(item) {
      this.data.claim_category_id = this.data.claim_category_id.filter(
        claimCategories => claimCategories !== item
      )
    },
    deleteClaim() {
      this.showModal({
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },
    processDelete() {
      let claim_id = this.id
      this.$store.dispatch('CUSTOMER_DELETE_CLAIM', { claim_id }).then(
        response => {
          if (response.data.status == 'success') {
            this.$router.push({
              path: '/claim/list'
            })
          }
        },
        error => {
          throw error
        }
      )
    }
  }
}
</script>

<style src="./Edit.scss" lang="scss" scoped></style>
