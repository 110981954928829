var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? [_c('div', {
    staticClass: "pa-9 mx-10"
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "card, card, article, date-picker"
    }
  })], 1)] : [_c('validation-observer', {
    ref: "observer"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "pa-9 mx-10"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "page-title font-weight-bold"
  }, [_vm._v("クレーム編集")]), _c('span', [_vm._v("クレーム情報を編集します。")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-content"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("顧客名")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "お客様",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-autocomplete', {
          staticClass: "customer-box-form-input",
          attrs: {
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": "auto",
            "placeholder": "顧客名を入力してください",
            "outlined": "",
            "items": _vm.customers,
            "item-text": "name",
            "item-value": "id",
            "dense": "",
            "autocomplete": "off",
            "search-input": _vm.search,
            "hide-no-data": "",
            "loading": _vm.smallLoading.customer,
            "return-object": ""
          },
          on: {
            "update:searchInput": function updateSearchInput($event) {
              _vm.search = $event;
            },
            "update:search-input": [function ($event) {
              _vm.search = $event;
            }, _vm.searchCustomer],
            "change": function change($event) {
              return _vm.updateSiteList();
            }
          },
          model: {
            value: _vm.data.customer,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "customer", $$v);
            },
            expression: "data.customer"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("物件名")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "sites",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          staticClass: "customer-box-form-input",
          attrs: {
            "hide-details": "auto",
            "placeholder": "物件名を入力してください",
            "outlined": "",
            "dense": "",
            "items": _vm.sites,
            "error-messages": errors,
            "error": errors.length !== 0,
            "item-text": "name",
            "item-value": "id",
            "disabled": !_vm.data.customer_id,
            "loading": _vm.smallLoading.site
          },
          model: {
            value: _vm.data.site_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "site_id", $$v);
            },
            expression: "data.site_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("サービスタイプ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "services",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "outlined": "",
            "placeholder": "サービスタイプを選択してください",
            "hide-details": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "items": _vm.services,
            "item-value": "id",
            "item-text": "name",
            "disabled": !_vm.data.site
          },
          model: {
            value: _vm.data.service_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "service_id", $$v);
            },
            expression: "data.service_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("タスク")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "クレーム分類",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "outlined": "",
            "placeholder": "task id / 日付 / サービス名 ",
            "hide-details": "auto",
            "error-messages": errors,
            "items": _vm.tasks,
            "item-value": "id",
            "item-text": "text",
            "disabled": _vm.data.service_id === null
          },
          model: {
            value: _vm.data.task_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "task_id", $$v);
            },
            expression: "data.task_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("クレーム分類")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "クレーム分類",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          attrs: {
            "outlined": "",
            "dense": "",
            "items": _vm.claimCategories,
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": "auto",
            "placeholder": "クレーム分類を選択してください",
            "chips": "",
            "multiple": "",
            "clearable": ""
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref6) {
              var attrs = _ref6.attrs,
                  item = _ref6.item,
                  select = _ref6.select;
              return [_c('v-chip', _vm._b({
                staticClass: "mb-1",
                attrs: {
                  "label": "",
                  "small": "",
                  "close": "",
                  "color": "#8C5F5F",
                  "text-color": "white"
                },
                on: {
                  "click": select,
                  "click:close": function clickClose($event) {
                    return _vm.removeTag(item.value);
                  }
                }
              }, 'v-chip', attrs, false), [_c('span', [_vm._v(_vm._s(item.text))])])];
            }
          }], null, true),
          model: {
            value: _vm.data.claim_category_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "claim_category_id", $$v);
            },
            expression: "data.claim_category_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("クレーム内容")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "des",
      "rules": "required|max:".concat(_vm.descriptionMaxLength)
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('v-textarea', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "outlined": "",
            "placeholder": "クレームの内容を箇条書きにしてください",
            "hide-details": "auto",
            "error-messages": errors,
            "maxlength": _vm.descriptionMaxLength
          },
          model: {
            value: _vm.data.description,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "description", $$v);
            },
            expression: "data.description"
          }
        })];
      }
    }])
  }), _c('div', {
    staticClass: "text-right mt-1"
  }, [_vm._v(" " + _vm._s("".concat(_vm.data.description.length, "/").concat(_vm.descriptionMaxLength)) + "文字 ")])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("対応内容")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "res",
      "rules": "required|max:".concat(_vm.responseMaxLength)
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-textarea', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "outlined": "",
            "placeholder": "どのように対応したかを記入してください",
            "hide-details": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "maxlength": _vm.responseMaxLength
          },
          model: {
            value: _vm.data.response,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "response", $$v);
            },
            expression: "data.response"
          }
        })];
      }
    }])
  }), _c('div', {
    staticClass: "text-right mt-1"
  }, [_vm._v(" " + _vm._s("".concat(_vm.data.response.length, "/").concat(_vm.responseMaxLength)) + "文字 ")])], 1)], 1)], 1)])], 1)], 1)], 1), _c('div', {
    staticClass: "create-task-footer px-6"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "#AD4545",
      "text": "",
      "rounded": "",
      "min-width": "150",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.deleteClaim
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("$trash")]), _vm._v(" 削除する ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "mr-8",
    attrs: {
      "to": {
        name: 'ClaimList'
      },
      "text": "",
      "rounded": "",
      "min-width": "150"
    }
  }, [_vm._v("キャンセル")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "#4F55A7",
      "min-width": "150",
      "disabled": _vm.smallLoading.site || _vm.smallLoading.customer,
      "loading": _vm.smallLoading.submit
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("更新する")])], 1)], 1)], 1)])])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }